import './App.css';
import Dashboard from './Dashboard';
import Landing from './Landing';
import Revision from './Revision';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import HomeMenu from './HomeMenu';
import RevisionMenu from './RevisionMenu';
import NotFound from './NotFound';

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path='/' element={<Landing/>} />
    <Route path='/dashboard' element={<Dashboard/>}>
      <Route path='revision' element={<RevisionMenu/>} />
      <Route path='revision/:subject' element={<RevisionMenu/>} />
      <Route path='home' element={<HomeMenu/>} />
      <Route path='' element={<HomeMenu/>} />
    </Route>
    <Route path='/revision/:id/' element={<Revision/>} />
    <Route path='*' element={<NotFound/>} />
  </>
));

function App() {
  return (
    <RouterProvider router={ router } />
  );
}

export default App;
