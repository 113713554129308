import logo from './logo.png';
import { Link, Outlet } from 'react-router-dom';
import menu from './menu.png';
import { useState } from 'react';

export default function Dashboard() {

    const [navState, setNavState] = useState('closed');

    function changeNavState() {
        if (navState === 'closed') {
            setNavState('open');
        } else {
            setNavState('closed');
        }
    }

    return (
        <div className='dashboard'>
            <nav className={navState}>
                <Link to={'/dashboard/home'}><img src={logo} className='logo'/></Link>
                <img src={menu} onClick={changeNavState}/>
                <div className='border-line'></div>
                <Link to={'/dashboard/home'}>🏠 Home</Link>
                <Link to={'/dashboard/courses'} className='coming-soon'>📚 Coming Soon</Link> {/* Courses */}
                <Link to={'/dashboard/revision'}>📖 Revision</Link>
                <Link to={'/dashboard/stats'} className='coming-soon'>📊 Coming Soon</Link> {/* Stats */}
                <Link to={'/dashboard'} className='coming-soon'>📝 Coming Soon</Link> {/* Practice Tests */}
                <div className='space'></div>
                <Link to={'/dashboard'} className='coming-soon'>⚙️ Coming Soon</Link> {/* Settings */}
                <Link to={'/dashboard'}>❌ Log Out</Link>
            </nav>
            <Outlet />
        </div>
    );
}