import { useEffect, useState } from "react";

export default function QuestionResult({ result }) {

    const [appearanceObj, setAppearanceObj] = useState({});

    useEffect(() => {
        if (result === 'correct') {
            setAppearanceObj({
                opacity: 1,
                isVisible: true,
                resultText: 'Correct ✅',
                backgroundColor: 'rgb(52, 205, 52)'
            });
        } else if (result === 'incorrect') {
            setAppearanceObj({
                opacity: 1,
                isVisible: true,
                resultText: 'Incorrect ❌',
                backgroundColor: 'rgb(255, 74, 64)'
            }); 
        } else {
            setAppearanceObj({
                opacity: 0,
                isVisible: appearanceObj.isVisible,
                resultText: appearanceObj.resultText,
                backgroundColor: appearanceObj.backgroundColor
            });
            setTimeout(() => {
                setAppearanceObj({
                    opacity: 0,
                    isVisible: false,
                    resultText: '',
                    backgroundColor: 'rgb(69, 69, 69)'
                });
            }, 500)
        }
    }, [result])

    return (
        <div className={`question-result ${appearanceObj.opacity === 0 ? 'transparent' : 'opaque'}`} style={{ visibility: appearanceObj.isVisible === true ? 'block' : 'none', backgroundColor: appearanceObj.backgroundColor, transition: appearanceObj.opacity === 0 ? 'opacity 0.5s linear' : 'opacity 0.15s linear', pointerEvents: appearanceObj.isVisible === false ? 'none' : 'auto'}}>
            <p>{appearanceObj.resultText}</p>
        </div>
    );
}

