import { useState } from "react";

export default function Question({title, answers, handleAnswer, correctAnswer}) {
    
    const [inputText, setInputText] = useState('Enter answer here...');

    function evaluateAnswer(answer) {
        if(answer === correctAnswer) {
            handleAnswer(true);
        } else {
            handleAnswer(false);
        }
    }

    return (
        <div className={`question ${answers.length > 0 ? "multiple-choice" : "written"}`}>
            <div className="title">
                <h1>{title}</h1>
            </div>
            <div className="answers">
                <button onClick={() => {evaluateAnswer(answers[0])}}>{answers[0]}</button>
                <button onClick={() => {evaluateAnswer(answers[1])}}>{answers[1]}</button>
                <button onClick={() => {evaluateAnswer(answers[2])}}>{answers[2]}</button>
                <button onClick={() => {evaluateAnswer(answers[3])}}>{answers[3]}</button>
            </div>
            <div className="input-field">
                <input type="text" value={inputText} onChange={(e) => {setInputText(e.target.value)}}/>
                <button onClick={() => {evaluateAnswer(inputText)}}>Submit</button>
            </div>
        </div>
    );
}