import logo from './logo.png';
import { useState } from "react";
import { Link } from "react-router-dom";

export default function HomeMenu() {
    const [setId, setSetId] = useState(2);
    const [htmlDisplaySets, setHtmlDisplaySets] = useState([]);

    function getSetListData(e) {
        e.preventDefault();
        fetch('https://www.quaesium.serv00.net/api/sets?data=title%2C%20id')
        .then((res) => {return res.text()})
        .then((str) => {
            setHtmlDisplaySets(JSON.parse(str).map((e) => {return <div key={e.id} className='set'><p>{e.title}</p><p>{e.id}</p></div>}));
        });
    }

    return (
        <div className='sets'>
            <p>home</p>
        </div>
    )
}